import { arrayToObject } from '../utilities';

function commonScore(pro, scoreFunc) {
  let { data } = pro.pro_data;
  data = arrayToObject(data);
  pro.score.score = scoreFunc(data);
  return pro;
}


function scoreVitalTrac(data) {
  let brVal = data.vitals.BR;
  let hrVal = data.vitals.HR || 0;

  if (brVal <= 0) {
    brVal = 'Unable to measure';
  } else if (brVal < 8 || brVal > 30) {
    brVal = 'Out of range';
  }

  if (hrVal <= 0) {
    hrVal = 'Unable to measure';
  } else if (hrVal < 40 || hrVal > 120) {
    hrVal = 'Out of range';
  }

  return `RR: ${brVal} | PR: ${hrVal} | IVC Algorithm v ${data.ivcVersion} | IVC App v ${data.webAppVersion}`;
}


const scoreFuncs = {
  VITAL_TRAC: (pro) => {
    return commonScore(pro, scoreVitalTrac);
  },
};

export default scoreFuncs;
